<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path clip-rule="evenodd"
          d="M40 15.6218C40 14.7733 39.0099 14.3104 38.3588 14.8544L28.3433 23.223C27.8641 23.6234 27.8651 24.3603 28.3454 24.7594L38.3608 33.0833C39.0124 33.6248 40 33.1615 40 32.3143L40 15.6218ZM31.3276 23.9873L37.5 18.8298L37.5 29.1172L31.3276 23.9873ZM25.25 25.3125L25.25 22.8125L22.75 22.8125L22.75 25.3125L25.25 25.3125ZM25.25 27.6875L25.25 30.1875L22.75 30.1875L22.75 27.6875L25.25 27.6875ZM25.25 35.125L25.25 32.625L22.75 32.625L22.75 35.125L25.25 35.125ZM25.25 40L25.25 37.5L22.75 37.5L22.75 40L25.25 40ZM25.25 17.875L25.25 20.375L22.75 20.375L22.75 17.875L25.25 17.875ZM25.25 15.4375L25.25 12.9375L22.75 12.9375L22.75 15.4375L25.25 15.4375ZM25.25 8L25.25 10.5L22.75 10.5L22.75 8L25.25 8ZM19.6546 23.1783C20.1349 23.5775 20.1358 24.3144 19.6566 24.7148L9.64114 33.0834C8.99006 33.6274 7.99995 33.1644 7.99995 32.316L7.99995 15.6236C7.99995 14.7763 8.98755 14.313 9.63912 14.8545L19.6546 23.1783Z"
          fill="inherit"
          fill-rule="evenodd"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-reflect-v-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
